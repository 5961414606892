export class PageLoaderModule {
  public constructor () {
  }
}

/// Function for fading out the loader page.
export function fadeOutLoader() {
  setTimeout(
    () => {
      $('.page-loader').fadeOut();
    }
    , 500
  );
  
}
