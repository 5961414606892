import { Injectable } from '@angular/core';

@Injectable()
export class BackgroundStretchModule {

  public backGroundImages: string[] = [
    // '../assets/img/bg/1.jpg',
    // '../assets/img/bg/2.jpg',
    // '../assets/img/bg/3.jpg',
    // '../assets/img/bg/4.jpg',
    // '../assets/img/bg/5.jpg',
    // '../assets/img/bg/6.jpg',
    // '../assets/img/bg/7.jpg',
    // '../assets/img/bg/8.jpg',
    // '../assets/img/bg/9.jpg',
    // '../assets/img/bg/10.jpg',
    // '../assets/img/bg/homePicture01.jpg'
    '../assets/img/stndBgs/bg_image_01_compressed.jpg',
    '../assets/img/stndBgs/bg_image_02_compressed.jpg',
    '../assets/img/stndBgs/bg_image_03_compressed.jpg',
    '../assets/img/stndBgs/bg_image_04_compressed.jpg'
  ];

  public aa: JQueryBackStretch.BackStretch;

  private _duration = 0;
  get ImageDurationTime(): number {
    return this._duration;
  }
  set ImageDurationTime(value: number) {
    this._duration = value;
  }

  private _fade = 0;
  get ImageFadeDuration(): number {
    return this._fade;
  }
  set ImageFadeDuration(value: number) {
    this._fade = value;
  }

  private _slideShow = false;
  get ImageSlideshowEnabled(): boolean {
    return this._slideShow;
  }
  set ImageSlideshowEnabled(value: boolean) {
    this._slideShow = value;
  }

  public returnBackgroundImages2dropDown() {
    return this.backGroundImages;
  }

  constructor() {
    console.log('++Class BackgroundStretch is initiated');
    this.aa = $.backstretch(this.returnBackgroundImages2dropDown()).pause();
  }

  private CheckForAlive() {
    if (this.aa != null) {
      console.log('aa object allready exist, therefor destory object');
      this.aa.destroy(true);
      console.log('Object destroyed');
    } else {
      console.log('aa object dosent exist therefor it will be created');
      this.aa = $.backstretch(this.returnBackgroundImages2dropDown());

    }
  }

  /// Function to set the background of the page.
  public setBackgroundImage(imgToShow?: number) {
    if (this.ImageSlideshowEnabled === true) {
      console.log('slideshow enable selected');
      this.aa.destroy(true);
      this.aa = $.backstretch(this.returnBackgroundImages2dropDown(), { fade: this.ImageFadeDuration, duration: this.ImageDurationTime });
    } else {
      console.log('slideshow = false');
      if (imgToShow == null) {
        imgToShow = 0;
      }
      this.aa.destroy(true);
      this.aa = $.backstretch(this.returnBackgroundImages2dropDown()).show(imgToShow);
      this.aa.pause();
    }
  }

  /// TODO: Fix naming.
  /// TODO: Create verbose logging in console as optional.
  /// TODO: Place imagelist as property.
}
