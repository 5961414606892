import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.css']
})
export class PageLoaderComponent implements OnInit {

  spinnerSelect = 2;

  @Input() color: string = '#7f58af'

  constructor() { }

  ngOnInit() {
  }

}
