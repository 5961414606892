import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackgroundStretchModule } from '@shared/background-stretch/background-stretch.module';

import { SharedRoutingModule } from '@shared/shared-routing.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    SharedRoutingModule
  ],
  providers: [
      BackgroundStretchModule
      
  ]
})
export class SharedModule { }

SharedRoutingModule