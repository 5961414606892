import { Component, OnInit } from '@angular/core';
import { ClockService } from '@shared/clock/clock.service';
import { write } from 'fs';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent implements OnInit {

  constructor(
    private clockService: ClockService
  ) { }

  time = this.clockService.getClock();
  timer: NodeJS.Timeout;

  ngOnInit() {
    this.timer = setInterval(() => {
      this.time = this.clockService.getClock();
    }, 1000);
    console.log ('Timer started');
  }

  ngOnDestroy() {
    clearInterval (this.timer);
  }
}
