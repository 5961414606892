import { Component, OnInit } from '@angular/core';
import { MainApplicationClass } from '@shared/MainApplicationClass'

import { fadeOutLoader } from '@shared/page-loader/page-loader.module';
import { BackgroundStretchModule } from '@shared/background-stretch/background-stretch.module';

import { VERSION } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  $appVersion: string = 'v' + this.obj.ApplicationInfo.appVersion;
  $development = this.obj.ApplicationInfo.appDevEnvironment;
  $developmentString: string = this.$development ? 'dev:true' : '';
  $angularVersion: string = this.$development ? 'Angular: ' + VERSION.full : '';


  public constructor(
    private obj: MainApplicationClass,
    private backGroundStretch: BackgroundStretchModule,
  ) { }


  ngOnInit(): void {
    this.obj.PageTitle = "Digital Insights Admin";
    fadeOutLoader();

    this.backGroundStretch.ImageDurationTime = 5000;
    this.backGroundStretch.ImageFadeDuration = 3000;
    this.backGroundStretch.ImageSlideshowEnabled = true;
    this.backGroundStretch.setBackgroundImage();
  }
  
}
