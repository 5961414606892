import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from '@shared/shared.module'
import { ModulesModule } from '@modules/modules.module'

import { PageLoaderComponent } from '@shared/page-loader/view/page-loader.component';
import { ClockComponent } from '@shared/clock/view/clock.component';
import { ClockService } from '@shared/clock/clock.service';
import { MainApplicationClass } from '@shared/MainApplicationClass'

@NgModule({
  declarations: [
    PageLoaderComponent,
    ClockComponent,
    AppComponent
  ],
  imports: [
    // Angular
    BrowserModule,
    HttpClientModule,

    // Core and Shared
    SharedModule,
    ModulesModule,

    // App
    AppRoutingModule


  ],
  providers: [
    MainApplicationClass,
    ClockService
  ],
  bootstrap: [
    AppComponent
  ]
})


export class AppModule {


}
