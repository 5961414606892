import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ClockService {
  // private clock: Observable<Date>;

  time = new Date();
  timer;
  
  constructor() { 
    // this.timer = setInterval(() => {
    //   this.time = new Date();
    // }, 1000);
  }
  
  getClock(): Date{
    return new Date;
  }
}
