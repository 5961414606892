import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser'
import { environment } from '@env'



/// General Application Class
@Injectable()
export class MainApplicationClass {

  public ApplicationInfo = new class {
    public appVersion: string = environment.packageVersion;
    public appDevEnvironment = !environment.production;
  }

  public constructor(
    private titleObj: Title
  ) { }

  // public appVersion: string = environment.packageVersion;
  // public appDevEnvironment = !environment.production;

  /// Get the title of the page
  get PageTitle(): string {
    return this.titleObj.getTitle();
  }
  /// Set the title of the page
  set PageTitle(value: string) {
    this.titleObj.setTitle(value);
  }

}
