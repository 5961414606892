<div class="page-loader">

  <div [ngSwitch]="spinnerSelect">
    <div *ngSwitchCase="1">

      <div class="lds-hourglass">
        <div class="lds-hourglass-after" [ngStyle]="{ background: color }">
        </div>
      </div>

    </div>

    <div *ngSwitchCase="2">

      <div class="page-loader__spinner">
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle>
        </svg>
      </div>

    </div>

  </div>



</div>