<script type="text/javascript">
  var timerStart = Date.now();
</script>
<main>
  <page-loader></page-loader>
  <div>Header</div>
  <div>Sidebar</div>
  <div>Theme selector</div>
  <section>Content</section>
</main>
<hr />

<ul>
  <li>
    <a routerLink="/">Welcome</a>
  </li>
  <li>
    <a routerLink="/apitest/test1">API test</a>
  </li>
</ul>


<router-outlet>
</router-outlet>

<footer>
  <div class="clockTag">
    <app-clock></app-clock>
  </div>
  <div class="versionTag">
    {{ $appVersion }}
    <span class="devTag">{{ $developmentString }}</span>
    <br />
    <span class="angularVersion">{{ $angularVersion }}</span>
  </div>
  
</footer>
